import Vue from 'vue'
import axios from "axios";
import ElementUI from '../element/index.js'
Vue.use(ElementUI)
let vm = new Vue();
let hasShownUnauthorizedAlert = false;
let config = {};
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'https://yunapi.cnuzi.com/api';
    // axios.defaults.baseURL = 'https://apitest.lezhireading.com/api';
    // 192.168.2.216
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://yunapi.cnuzi.com/api';
    // axios.defaults.baseURL = 'https://apitest.lezhireading.com/api';
}

axios.defaults.timeout = 50000;
//http request 拦截器
axios.interceptors.request.use(
    config => {
        if (
            config.headers["Content-Type"] !=
            "application/x-www-form-urlencoded;charset:UTF-8"
        ) {
            config.headers["Content-Type"] = "application/json;charset=UTF-8";
        }
        const token = $cookies.get("token");
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        if ((config.url).substring(0, 38) != '/learn/calligraphy/withoutHeader/login') {
            if (window.electronJoinClass) {
                config.headers["deviceInfo"] = 'tc';
            } else {
                config.headers["deviceInfo"] = 'pc';
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
    response => {
        if (response.data.status == 403) {
            if (!hasShownUnauthorizedAlert) {
                hasShownUnauthorizedAlert = true;
                vm.$msgbox({
                    type: 'warning',
                    title: '提示',
                    message: '<div style="display: flex;align-items: center;" class="flex-align-center">登录超时，请重新登录</div>',
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: "确定",
                    center: true,
                    customClass: 'MessClass',
                    confirmButtonClass: 'warningClass'
                }).then(() => {
                    sessionStorage.clear();
                    window.location.href = '/logins'
                    router.push({
                        path: "/logins",
                        query: {
                            redirect: router.currentRoute.fullPath
                        } //从哪个页面跳转
                    })
                    window.routes.push({
                        path: '#/logins',
                        query: {
                            redirect: router.currentRoute.fullPath
                        } //登录成功后跳入浏览的当前页面
                    })
                });
            }
        }
        return response;
    },
    err => {
        console.log(err);

        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    console.log("错误请求");
                    break
                case 401:
                    console.log("未授权，请重新登录");
                    if (!hasShownUnauthorizedAlert) {
                        hasShownUnauthorizedAlert = true;
                        vm.$msgbox({
                            type: 'warning',
                            title: '提示',
                            message: '<div style="display: flex;align-items: center;" class="flex-align-center">登录超时，请重新登录</div>',
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: "确定",
                            center: true,
                            customClass: 'MessClass',
                            confirmButtonClass: 'warningClass'
                        }).then(() => {
                            sessionStorage.clear();
                            window.location.href = '/logins'
                            router.push({
                                path: "/logins",
                                query: {
                                    redirect: router.currentRoute.fullPath
                                } //从哪个页面跳转
                            })
                            window.routes.push({
                                path: '#/logins',
                                query: {
                                    redirect: router.currentRoute.fullPath
                                } //登录成功后跳入浏览的当前页面
                            })
                        });
                    }
                    break
                case 403:
                    console.log("拒绝访问");
                    break
                case 404:
                    console.log("请求错误,未找到该资源");
                    break
                case 405:
                    console.log("请求方法未允许");
                    break
                case 408:
                    console.log("请求超时");
                    break
                case 500:
                    console.log("服务器端出错");
                    break
                case 501:
                    console.log("网络未实现");
                    break
                case 502:
                    console.log("网络错误");
                    break
                case 503:
                    console.log("服务不可用");
                    break
                case 504:
                    console.log("网络超时");
                    break
                case 505:
                    console.log("http版本不支持该请求");
                    break
                default:
                    console.log(`连接错误${err.response.status}`);
            }
        } else {
            console.log("连接到服务器失败");
        }
        return Promise.resolve(err.response);
    }
);

// 添加 路径
/*
 * json转成url格式
 * 中文encode
 */
function jsonUrlFormat(data = {}) {
    let param = function (obj) {
        let query = "";
        let name, value, fullSubName, subName, subValue, innerObj, i;
        for (name in obj) {
            value = obj[name];
            if (value instanceof Array) {
                for (i = 0; i < value.length; ++i) {
                    subValue = value[i];
                    fullSubName = name + "[]";
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += param(innerObj) + "&";
                }
            } else if (value instanceof Object) {
                for (subName in value) {
                    subValue = value[subName];
                    fullSubName = name + "[" + subName + "]";
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += param(innerObj) + "&";
                }
            } else if (value !== undefined && value !== null) {
                query +=
                    encodeURIComponent(name) + "=" + encodeURIComponent(value) + "&";
            }
        }
        return query.length ? query.substr(0, query.length - 1) : query;
    }
    // 增加 去缓存时间戳
    data.noCache = new Date().getTime();
    // 资源平台appId增加
    // if(data.apiType == 'res') data.appId = window.NewResAppId
    // 递归将json转成url, 增加sign签名 和 去缓存时间戳noCache
    return data.toString() === "[object Object]" ? param(data) : data;
}

/**
 * 处理post请求，简单encode封装
 * 暂未使用
 */
function doJson(params) {
    return params;
}

function urlEncode(param, key, encode) {
    if (param == null) return "";
    var paramStr = "";
    var t = typeof param;
    if (t == "string" || t == "number" || t == "boolean") {
        paramStr +=
            "&" +
            key +
            "=" +
            (encode == null || encode ? encodeURIComponent(param) : param);
    } else {
        for (var i in param) {
            var k =
                key == null ?
                i :
                key + (param instanceof Array ? "[" + i + "]" : "." + i);
            paramStr += urlEncode(param[i], k, encode);
        }
    }
    return paramStr;
}

function convertObj(data) {
    var a_result = [];
    for (var key in data) {
        var value = data[key];
        console.log(value);
        if (value.constructor == Array) {
            value.forEach(function (a_value) {
                a_result.push(key + "=" + a_value);
            });
        } else {
            a_result.push(key + "=" + value);
        }
    }
    console.log(a_result.join("&"));
    return a_result.join("&");
}

/*
 * 封装fetch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
function fetch(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                url + data + "?" + jsonUrlFormat()
                //   {
                //   params:data
                // }
            )
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            })
    });
}
/*
 * 封装fetch_put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
function fetch_put(url, data1, data2) {
    return new Promise((resolve, reject) => {
        axios
        // .put(
        //     url + data1 + "?" + jsonUrlFormat(data2)
        //     //   {
        //     //   params:data
        //     // }
        // )
        // .then(response => {
        //     resolve(response.data);
        // })
        // .catch(err => {
        //     reject(err);
        // })
        axios.put(url + data1 + "?" + jsonUrlFormat(), doJson(data2)).then(
            response => {
                resolve(response);
            },
            err => {
                reject(err);
            }
        );
    });
}
/*
 * 封装get请求
 * @param url
 * @param data
 * @returns {Promise}
 */
function get(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                url + "?" + jsonUrlFormat(data),
            )
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            })
    });
}
/*
 * 封装get请求下载文件流
 * @param url
 * @param data
 * @returns {Promise}
 */
function getDoc(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                url + "?" + jsonUrlFormat(data), {
                    responseType: 'blob'
                }

            )
            .then(response => {
                // console.log('response:',response)
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            })
    });
}
/*
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url + "?" + jsonUrlFormat(), doJson(data)).then(
            response => {
                resolve(response.data);
            },
            err => {
                reject(err);
            }
        );
    })
}

/*
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url + "?" + jsonUrlFormat(), data).then(
            response => {
                resolve(response.data);
            },
            err => {
                reject(err);
            }
        );
    })
}

/*
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url + "?" + jsonUrlFormat(), doJson(data)).then(
            response => {
                resolve(response);
            },
            err => {
                reject(err);
            }
        );
    })
}
/**
 * 封装deletes请求
 * @param url
 * @param data
 * @returns {Promise}
 */
function del(url, data = {}) {
    return new Promise((resolve, reject) => {
        // axios.delete( descriptor.url + '?' + DoApi.jsonUrlFormat({}), DoApi.doJson(args));
        axios.delete(url + data + "?" + jsonUrlFormat())
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

function get2(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                url + "?" + jsonUrlFormat(data), {
                    responseType: 'blob'
                })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            })
    });
}

export {
    get,
    getDoc,
    put,
    patch,
    post,
    del,
    fetch,
    fetch_put,
    urlEncode,
    convertObj,
    get2
};