import {
      get,
      put,
      patch,
      post,
      fetch
} from "../ApiDecorator"; // 倒入 api
const Myclass = {
      //新功能
      // （根据年级查询单元表+任务+字）
      getUnittable(data) {
            // return fetch("/teach/unit/queryUnitAllListByTermId/", data);
            return get("/teach/unit/queryUnitAllListByTermId/", data);
      },
      getClassWordsVoList(data) {
            return post("/teach/pmWrite/getClassUnitLearnedWordsVoList", data);
      },
      //（根据任务查询步骤）
      getStepdata(data) {
            return fetch("/teach/task/queryStepByTaskId/", data);
      },
      //( 根据任务ID查询任务详情)
      getquerytask(data) {
            return fetch("/teach/task/queryTaskDetailByTaskId/", data);
      },
      getTaskId(data) {
            return fetch("/teach/word/getLearnInfoByWordId/", data);
      },
      //选择单字
      // getHanzi(data) {
      //       return fetch("/teach/unit/queryUnitAllListByTermId/", data);
      // },
      //图片
      getImg(data) {
            return fetch("/teach/import/getTaskShowImage/", data);
      },
      //图片2

      getImgList(data) {
            return get("/teach/pmWrite/getWordPictureList", data);

      },
      //午写模式
      getWeekIdsByGradeId(data) {
            return get("/teach/pmWrite/getWeekIdsByGradeId/", data);
      },
      getWordsVoList(data) {
            return post("/teach/pmWrite/getClassLearnedWordsVoList/", data);
      },

      getTaskIdByWordId(data) {
            return get("/teach/pmWrite/getTaskIdByWordId", data);
      },
      // 课堂模式(下课标记)
      // 午写模式
      postLearnedWord(data) {
            return post("/teach/pmWrite/classLearnedWord", data);
      },
      //课堂模式
      classUnitLearnedWord(data) {
            return post("/teach/pmWrite/classUnitLearnedWord", data);
      },
      classLearnedTask(data) {
            return post("teach/unit/classLearnedTask", data);
      },
      //接收图片
      queryTeaUploadWorksList(data) {
            return get("learn/word/test/queryTeaUploadWorksList", data);
      },
      //学生测评报告
      getTeachAssessReport(data) {
            return get("learn/word/test/getTeachAssessReport", data);
      },
      //学生测评报告new)
      getWordReportEval(data) {
            return get("learn/open/word/activity/getWordReportEval", data);
      },
      getUserhistory(data) {
            return get("learn/historyTestResults/history", data);
      },
      TestResultsViewLog(data) {
            return post("learn/historyTestResults/view-log", data);
      },
      //学生测评评价new)
      getWordEval(data) {
            return get("learn/open/word/activity/getWordEval", data);
      },
      //个人测评报告
      getAssessReport(data) {
            return get("learn/word/test/getAssessReport", data);
      },
      //查询学生
      queryStudentList(data) {
            return get("learn/word/test/queryStudentList", data);
      },
      //学生作品集合
      getTeacherWorkHistory(data) {
            return get("learn/word/test/getTeacherWorkHistory", data);
      },
      //个人作品集合
      getQQTestList(data) {
            return get("learn/word/test/getQQTestList", data);
      },

      getLessonParams(data) {
            return get("learn/word/test/getLessonParams", data);
      },
      //
      getQQTestDetail(data) {
            return post("learn/word/test/getQQTestDetail", data);
      },

      //删除时间轴学生作品列表
      deleteTeacherWork(data) {
            return get("learn/word/test/deleteTeacherWork", data);
      },
      //删除时间轴我的作品列表
      deleteQQWork(data) {
            return get("learn/word/test/deleteQQWork", data);
      },

      //删除学生作品集
      deleteTestList(data) {
            return get("learn/word/test/deleteTestList", data);
      },
      //删除我的作品集
      deleteQQTestList(data) {
            return get("learn/word/test/deleteQQTestList", data);
      },
      //记录用户操作
      configWorkParams(data) {
            return post("learn/word/test/configWorkParams", data);
      },

}
export default Myclass;