import Vue from 'vue'
//请求用户信息--改封装用于及时更新用户头像
function Portrait(value) {
    return new Promise((resolve, reject) => {
        Vue.prototype.$Api.Home.getUserInfo()
            .then(res => {
                if (window.electronJoinClass || value != undefined) {
                    resolve(res)
                } else {
                    resolve(res.data.avatar)
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}
export default {
    Portrait
}