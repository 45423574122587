// 导入自己需要的组件
import {
    Dialog,
    Input,
    InputNumber,
    Checkbox,
    Button,
    ButtonGroup,
    Form,
    FormItem,
    Icon,
    Row,
    Badge,
    Rate,
    Image,
    MessageBox,
    Message,
    Tooltip,
    Progress,
    Timeline,
    TimelineItem,
    Switch,
    Popover,
    Loading,
    Select,
    Option,
    DatePicker,
    TimePicker,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Radio,
    RadioGroup,
    TimeSelect,
    Upload,
    Carousel,
    CarouselItem,
    Pagination,
} from 'element-ui';
const element = {
    install: function (Vue) {
        Vue.use(Dialog);
        Vue.use(Input);
        Vue.use(InputNumber);
        Vue.use(Checkbox);
        Vue.use(Button);
        Vue.use(ButtonGroup);
        Vue.use(Form);
        Vue.use(FormItem);
        Vue.use(Icon);
        Vue.use(Row);
        Vue.use(Badge);
        Vue.use(Rate);
        Vue.use(Image);
        Vue.use(Tooltip);
        Vue.use(Progress);
        Vue.use(Timeline);
        Vue.use(TimelineItem);
        Vue.use(Switch);
        Vue.use(Popover);
        Vue.use(Select);
        Vue.use(DatePicker);
        Vue.use(TimePicker);
        Vue.use(Option);
        Vue.use(Dropdown);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);
        Vue.use(Loading.directive);
        Vue.use(Radio);
        Vue.use(RadioGroup);
        Vue.use(TimeSelect);
        Vue.use(Upload);
        Vue.use(Carousel);
        Vue.use(CarouselItem);
        Vue.use(Pagination);
        Vue.prototype.$loading = Loading.service;
        Vue.prototype.$msgbox = MessageBox;
        Vue.prototype.$alert = MessageBox.alert;
        Vue.prototype.$confirm = MessageBox.confirm;
        Vue.prototype.$prompt = MessageBox.prompt;
        Vue.prototype.$message = Message;
    }
}
export default element