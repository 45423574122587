<template>
  <div class="tip">
    <el-dialog title="提示" :visible="dialogVisible" width="30%" center :before-close="clear">
      <!-- :show-close='false' -->
      <span class="spans">{{ hint }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">知道了</el-button>
        <!-- <el-button type="primary" @click="sure">前往征订</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      hint: (state) => state.home.hint,
    }),
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "当前暂无学生完成征订",
    },
  },
  methods: {
    clear() {
      this.$emit("clear", false);
    },
    sure() {
      this.$emit("sure", false);
    },
  },
};
</script>
<style lang="less">
@import "less/subscribe.less";
</style>