import {
    get,
    put,
    patch,
    post,
    fetch
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Home = {
    //登录
    login(data) {
        return post("/learn/calligraphy/login", data);
    },
    login2(data) {
        return post("/learn/calligraphy/withoutHeader/login", data);
    },
    //参数登录
    getThirdAuthUserInfo(data) {
        return get("/teach/thirdAuth/getThirdAuthUserInfo", data);
    },
    getDirectUrl(data) {
        return get("/teach/teacherQQ/getReDirectUrl", data);
    },
    getDirectUrl2(data) {
        return get("/teach/teacherQQ/getReDirectUrl2", data);
    },
    queryWordUrl(data) {
        return get("/teach/teacherQQ/queryWordUrl", data);
    },
    checkTencentWord(data) {
        return get("/teach/teacherQQ/checkTencentWord", data);
    },
    qrCode(data) {
        // return post("https://ssyzpccdn.lezhireading.com/cnuzi/pc/qr_code/login/query", data);
        return post("/cnuzi/pc/qr_code/login/query", data);
    },
    CreateLesson(data) {
        return post("/learn/word/test/createLesson", data);
    },
    toReDirectReport(data) {
        return get("/teach/teacherQQ/toReDirectReport", data);
    },



    //个人信息
    getUserInfo(data) {
        return get("/learn/calligraphy/getUserInfo", data);
    },
    //获取上课信息
    getCourseInfo(data) {
        return get("/learn/calligraphy/getCourseInfo", data);
    },
    //获取上课信息(课程内容信息)
    getTextInfo(data) {
        return get("/learn/calligraphy/getTextInfo", data);
    },
    //获取作业列表
    getHomeworkList(data) {
        return get("/learn/calligraphy/getHomeworkList", data);
    },
    getcheckTask(data) {
        return get("/learn/calligraphy/checkTask", data);
    },
    // 获取已提交学生作业
    getshow_works(data) {
        return get("/learn/task/show_works", data);
    },
    // 获取已提交学生作业
    // task/check_task
    //获取课程内容
    getTextInfo(data) {
        return get("/learn/calligraphy/getTextInfo", data);
    },
    //学校征订
    school_subscription(data) {
        return get("/learn/order/have_show", data);
    },
    //班级征订
    class_subscription(data) {
        return get("/learn/order/check_up", data);
    },
    // 开始上课
    startClass(data) {
        return post("/learn/calligraphy/info/startCourse", data);
    },
    // 更新上课时长
    putClass(data) {
        return put("/learn/calligraphy/info/updateCourseTime", data);
    },
    // 更新上课时长


    //视频解密
    getVid(data) {
        // return get("https://studytest.lezhireading.com:20443/api/write/playsafe", data);
        // return get("https://apitest.lezhireading.com/api/write/playsafe", data);
        // return get("https://api.lezhireading.com/api/write/playsafe", data);
        // return get("https://ssyzpccdn.lezhireading.com/cnuzi/api/write/playsafe", data);
        return get("/write/playsafe", data);
    },
    //    //判断身份
    //    regPhone(data) {
    //     return post("/user/regphone", data);
    // },



    //查询用户身份
    getroles(data) {
        return get("/learn/auth/user/roles", data);
    },
    //查询用户参与项目
    getProjectList(data) {
        return get("/learn/overview/user_projects", data);
    },
    //查询进度统计信息
    getExtentInfo(data) {
        return get("/learn/overview/user_project_total", data);
    },
    //查询用户课程计划
    inquireProjectExtent(data) {
        return get("/learn/overview/user_daily", data)
    },
    getSchedule(data) {
        return get("/learn/overview/user_schedule", data)
    },
    getScheduleOne(data) {
        return get("/learn/overview/user_schedule_one", data)
    },
    getTrainPlanInfo(data) {
        return fetch("/learn/course/item/detail/", data)
    },
    getDialogInfo(data) {
        return get("/learn/course/teach/course/checkExpertLive", data);
    },

    //新功能
    // getClassData(data) {
    //     return fetch("/teach/unit/queryUnitAllListByTermId/", data);
    // },
    getStepdata(data) {
        return fetch("/teach/task/queryStepByTaskId/", data);
    },
    getTaskId(data) {
        return fetch("/teach/word/getLearnInfoByWordId/", data);
    },
    getTestPicture(data) {
        return fetch("/teach/import/testPicture/", data);
    },
    getTeacherUploadInfo(data) {
        return get("/learn/word/test/getTeacherUploadInfo", data);
    },
    getcheckTencentUser(data) {
        return get("/teach/teacherQQ/checkTencentUser", data);
    },

    //查询用户身份
    checkSchoolRedirectUrl(data) {
        return get("/learn/course/checkSchoolRedirectUrl", data);
    },
    //判断用户角色组织
    queryUserMaxOrg(data) {
        return get("/learn/screen/statistics/queryUserMaxOrg", data);
    },
    // 阅读课程获取图书
    getBook(data) {
        return get("/learn/course/toTeacherQQCourseUrl", data)
    },
    // 上课统计
    addLog(data) {
        return post("/learn/course/addLog", data)
    },
    // 获取软硬笔体系课程
    getPenLesson(data) {
        return get("/learn/teach/queryLesson", data)
    },
    // 我教的课-获取班级列表
    getMyTeachClass() {
        return get("/learn/teach/queryTeacherClass")
    },
    // 我教的课-获取单元
    getMyTeachCell(data) {
        return get("/learn/teach/queryUnit", data)
    },
    // 我教的课-获取单元 -简洁版
    getConciseUnit(data) {
        return get("/learn/teach/getConciseUnit", data)
    },
    // 我教的课-根据单元id获取单元测试的字
    getCellWord(data) {
        return get("/learn/teach/queryWords", data)
    },
    // 我教的课-根据单元id获取单元测试的字
    getConciseWords(data) {
        return get("/learn/teach/getConciseWords", data)
    },
    // 我教的课-获取每日一字列表
    getDayWord(data) {
        return get("/learn/teach/queryWeekWords", data)
    },
    // 获取新闻通知列表
    getNewsList(data) {
        return get("/learn/auth/third/queryJump2Cnuen", data)
    },
    // 校本课程获取跳转地址
    queryTencentLesson(data) {
        return get("/learn/teach/queryTencentLesson", data)
    },
    // 我教的课-获取班级列表
    getSysLessonList(data) {
        return get("/learn/sys-resource/getSysLessonList", data)
    },
    // 我教的课-获取班级列表
    getSysLessonList(data) {
        return get("/learn/sys-resource/getSysLessonList", data)
    },
    // 我教的课-课件类型 5:校本课;6:我的课程
    getShareCourseware(data) {
        return get("/learn/teach/getShareCourseware", data)
    },
    //校本课
    getcourselib(data) {
        return get("/learn/course/get-course-lib", data)
    },
    getschoolcourselist(data) {
        return get("/learn/course/get-school-course-list", data)
    },
    //校本课
    getcourselibRel(data) {
        return get("/learn/course/get-course-lib-rel", data)
    },
     //我的课程
     getmycourse(data) {
        return get("/learn/course/get-my-course", data)
    },
};

// 默认全部倒出
export default Home;