import {
      get,
      put,
      patch,
      post,
      fetch
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */
const Profile = {
      updateUserInfo(data) {
            return put("/learn/auth/updateUserInfo", data);
      },
      getsendCode(data) {
            return post("/learn/auth/sendCode", data);
      },
      updateUserPhone(data) {
            return post("/learn/auth/updateUserPhone", data);
      },
      updateUserPassword(data) {
            return post("/learn/auth/updateUserPassword", data);
      },
      updateAvatar(data) {
            return post("/learn/user/avatar", data);
      },
      checkUserOldPwd(data) {
            return post("/learn/auth/checkUserOldPwd", data);
      }


}
// 默认全部倒出
export default Profile;