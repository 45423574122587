const surfaceHome = () =>
  import("../views/excellentWords/surfaceHome.vue")
const activityStatistics = () =>
  import("../views/excellentWords/activityStatistics.vue")
const statisticalDetails = () =>
  import("../views/excellentWords/statisticalDetails.vue")
const statisticsHome = () =>
  import("../views/excellentWords/statisticsHome.vue")

export default [{
    path: '/surfaceHome',
    name: 'surfaceHome',
    meta: {
      title: '优秀作品',
      keepAlive: false
    },
    
    component: surfaceHome,

  },
  {
    path: '/statisticsHome',
    name: 'statisticsHome',
    // meta: {
    //   title: '查看统计',
    //   keepAlive: false
    // },
    redirect: "/statisticsHome/activityStatistics",
    component: statisticsHome,
    children: [{
        path: 'activityStatistics',
        name: 'activityStatistics',
        meta: {
          title: '查看统计',
          keepAlive: false
        },
        component: activityStatistics
      },
      {
        path: 'statisticalDetails',
        name: 'statisticalDetails',
        meta: {
          title: '活动统计',
          keepAlive: false
        },
        component: statisticalDetails,
      }
    ]
  },
]