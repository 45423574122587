import {
    get,
    put,
    patch,
    post,
    fetch
} from "../ApiDecorator"; // 倒入 api

const manage = {
    // 查询项目筛选列表
    getPrejectList(data){
        return get("/learn/teach/queryTeamParam",data)
    },
    // 查询分组筛选列表
    getGroupList(data){
        return get("/learn/teach/queryGroupParam",data)
    },
    // 查询任务筛选列表
    getTaskList(data){
        return get("/learn/teach/queryTaskParam",data)
    },
    // 根据条件查询作品列表
    getWorkList(data){
        return get("/learn/teach/queryWorkPage",data)
    },
    // 是否推优作品
    excellentWord(data){
        return get("/learn/teach/changeExcellent",data)
    },
    // 检查是否是管理员
    isManager(){
        return get("/learn/teach/checkIsManager")
    },
    // 查询班级筛选列表
    getClassList(){
        return get("/learn/teach/queryUserClass")
    },
    // 获取活动统计数据
    getStatistics(data){
        return post("/learn/word/test/getActivityCountByUser",data)
    },
    // 获取子活动统计数据
    getChildStatistics(data){
        return post("/learn/word/test/getSubActivityUserData",data)
    },
    // 子活动数据下载
    exportData(data){
        return get("/learn/word/test/getSubActivityUserDataEx/export",data)
    }

}
export default manage;