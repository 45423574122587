import {
    get,
    put,
    patch,
    post,
    fetch
} from "../ApiDecorator"; // 倒入 api

const CopyBook = {
    getGrade(){
        return get("/learn/model/queryUserGradeList")
    },
    getClassList(){
        return get('/learn/model/queryUserClass')
    },
    getClassStu(data){
        return post('/learn/model/queryClassUsers',data)
    },
    getCopyBookList(data){
        return get('/learn/model/queryModelPage',data)
    },
    createCopyBook(data){
        return post('/learn/model/buildModel',data)
    },
    copyBookDetail(data){
        return get('/learn/model/queryModelById',data)
    },
    saveLiving(data){
        return post('/learn/model/saveLivingLesson',data)
    }
    
}
export default CopyBook