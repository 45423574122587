const Square = () =>
      import("../views/courseSquare/square")
const Sector = () =>
      import("../views/courseSquare/sector")
export default [{
      path: '/square',
      name: 'square',
      meta: {
            title: '课程广场',
            keepAlive: false
      },
      component: Square,
      children: [
            {
                  path: 'sector',
                  name:'sector',
                  meta: {
                        title: '课程广场',
                        keepAlive: false
                  },
                  component: Sector,
            }
      ]
},
]