const activity = ()=> import("../views/activity/activity");
const activity_list = ()=>import("../views/activity/activity_list");
const activity_login = () =>import("../views/activity/activity_login")
export default [
    {
        path:"/newactivity",
        name:"newactivity",
        meta: {
            title: '特色活动',
            keepAlive: false
        },
        component:activity
    },
    {
        path:"/newactivity_list",
        name:"newactivity_list",
        meta: {
            title: '特色活动',
            keepAlive: false
        },
        component:activity_list
    },
    {
        path:"/newactivity_login",
        name:"newactivity_login",
        meta: {
            title: '特色活动',
            keepAlive: false
        },
        component:activity_login
    }
]