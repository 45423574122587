import index from './router/index'
import liveRouter from './router/live-router'
import courseSquareRouter from './router/courseSquare-router'
import screenRouter from './router/screen-router'
import excellentWords from './router/excellentWords-router'
import copybook from './router/copybook-router'
import activity from './router/activity-router'
export default [
    ...index,
    ...liveRouter,
    ...courseSquareRouter,
    ...screenRouter,
    ...excellentWords,
    ...copybook,
    ...activity
]