import {
    get,
    put,
    patch,
    post,
    fetch
} from "../ApiDecorator"; // 倒入 api

const Activity = {
    getWorksOpen(data){
        return get("/learn/open/word/activity/queryActivityWorks",data)
    },
    getWorks(data){
        return get("/learn/word/test/activity/queryActivityWorks",data)
    },
    getWorkDetailOpen(data){
        return get("/learn/open/word/activity/getTeachAssessReport",data)
    },
    getWorkDetail(data){
        return get("/learn/word/test/getTeachAssessReport",data)
    },
    // 检查当前班级是否有活动
    checkHasActive(){
        return get('/learn/word/test/activity/checkHaveWork')
    },
    // 获取活动学校
    activitySchool(data){
        return get('/learn/word/test/activity/queryActivitySchool',data)
    },
    // 获取活动年级
    activityGrade(data){
        return get('/learn/word/test/activity/queryActivityGrade',data)
    },
    // 获取活动班级
    activityClass(data){
        return get('/learn/word/test/activity/queryActivityClass',data)
    },
    // 检查登录页活动是否开启
    checkIndexActivity(){
        return get('/learn/open/word/activity/checkIndexActivity')
    },
    // 获取特色活动列表
    getActivityList(){
        return get('/learn/word/test/activity/queryActivityList')
    }
}
export default Activity;